<template>
  <b-card>

    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="7"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="userData.avatar"
            :text="avatarText(userData.shortName)"
            :variant="`light-${userData.role.variant}`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ userData.fullName }}
              </h4>
              <span class="card-text">{{ userData.shortName }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                v-if="!userData.verified"
                variant="info"
                @click="resendActivationEmail(userData)"
              >
                {{ $t('Modules.User.Label.Resend activation email') }}
              </b-button>
              <b-button
                :to="{ name: 'users-edit', params: { id: userData.id } }"
                variant="primary"
                class="ml-1"
              >
                {{ $t('Edit') }}
              </b-button>
              <b-button
                variant="outline-danger"
                class="ml-1"
                @click="deleteUser(userData)"
              >
                {{ $t('Delete') }}
              </b-button>
            </div>
          </div>
        </div>

        <!-- User Stats -->
        <!-- <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-primary"
              rounded
            >
              <feather-icon
                icon="DollarSignIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                23.3k
              </h5>
              <small>Monthly Sales</small>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <b-avatar
              variant="light-success"
              rounded
            >
              <feather-icon
                icon="TrendingUpIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                $99.87k
              </h5>
              <small>Annual Profit</small>
            </div>
          </div>
        </div> -->
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="5"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Username</span>
            </th>
            <td class="pb-50">
              {{ userData.username }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Status</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.status }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Role</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.role.title }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Country</span>
            </th>
            <td class="pb-50">
              {{ userData.country }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Contact</span>
            </th>
            <td>
              {{ userData.contact }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import usersList from '../list/usersList'
import UserRepository from '@/modules/user/repository/userRepository'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { resolveUserRoleVariant } = usersList()
    return {
      avatarText,
      resolveUserRoleVariant,
    }
  },
  methods: {
    async deleteUser(item) {
      const vm = this
      if(await vm.showDeleteConfirmDialog()) {
        UserRepository.delete(item.id)
        .then(() => {
          this.$router.push({ name: 'users' })
        })
      }
    },
    async resendActivationEmail(item) {
      const vm = this
      if(await vm.showResendActivationEmailConfirmDialog()) {
        UserRepository.resendActivationEmail(item)
        .then(() => {

        })
      }
    },
    async showResendActivationEmailConfirmDialog() {
      const vm = this
      let confirmValue = null;
      await vm.$bvModal.msgBoxConfirm(vm.$i18n.t('Modules.User.Message.Are you sure you want to resend activation email?'), {
          title: vm.$i18n.t('Warning'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: vm.$i18n.t('Yes'),
          cancelTitle: vm.$i18n.t('No'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
      })
      .then(value => {
          confirmValue = value;
      })
      return confirmValue;
  }
  }
}
</script>

<style>

</style>
